<template>
  <div id="app" >
    <router-view/>
  </div>
</template>

<style>
#app{
  min-height: 100vh;
}
</style>
<script>
import DevicePixelRatio from '@/utils/rem.js';

export default {
  methods: {
  },
  created () {
    new DevicePixelRatio().init()
  }
}


</script>